// 黑名单
<template>
    <div class="menu">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="统计栏" name="1">
                    <div class="out-box">
                        <el-row :gutter="30">
                            <el-col :span="4">
                                <div class="statistical-son-box bg-color1">
                                    <div>
                                        <div class="font-color title1">
                                            <icon-font type="icon-cishu_data-sheet-01" class="font-size4"/>
                                            今日总次数
                                        </div>
                                        <div class="font-color font-size1">{{ totalList.checkTotal?.todayTotal == null?'0':totalList.checkTotal?.todayTotal }}</div>
                                        <div>
                                            <span class="font-color">昨日总次数</span>
                                            <span class="font-color font-size2">{{ totalList.checkTotal?.yesterdayTotal == null?'0':totalList.checkTotal?.todayTotal }}</span>
                                        </div>
                                        <div>
                                            <span class="font-color">本月总次数</span>
                                            <span class="font-color font-size3">{{ totalList.checkTotal?.monthTotal == null?'0':totalList.checkTotal?.todayTotal }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="statistical-son-box bg-color2">
                                    <div>
                                        <div class="font-color title1">
                                            <icon-font type="icon-cishu_data-sheet-05" class="font-size6"/>
                                            今日拦截次数
                                        </div>
                                        <div class="font-color font-size5">{{ totalList.interceptCountTotal?.todayTotal == null?'0':totalList.interceptCountTotal?.todayTotal }}</div>
                                        <div>
                                            <span class="font-color">昨日拦截次数</span>
                                            <span class="font-color font-size2">{{ totalList.interceptCountTotal?.yesterdayTotal == null?'0':totalList.interceptCountTotal?.todayTotal }}</span>
                                        </div>
                                        <div>
                                            <span class="font-color">本月拦截次数</span>
                                            <span class="font-color font-size3">{{ totalList.interceptCountTotal?.monthTotal == null?'0':totalList.interceptCountTotal?.todayTotal }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="statistical-son-box bg-color3">
                                    <div>
                                        <div class="font-color title1">
                                            <icon-font type="icon-cishu_data-sheet-03" class="font-size4"/>
                                            今日查询次数
                                        </div>
                                        <div class="font-color font-size1">{{ totalList.queryCountTotal?.todayTotal == null?'0':totalList.queryCountTotal?.todayTotal }}</div>
                                        <div>
                                            <span class="font-color">昨日查询次数</span>
                                            <span class="font-color font-size2">{{ totalList.queryCountTotal?.yesterdayTotal == null?'0':totalList.queryCountTotal?.todayTotal }}</span>
                                        </div>
                                        <div>
                                            <span class="font-color">本月查询次数</span>
                                            <span class="font-color font-size3">{{ totalList.queryCountTotal?.monthTotal == null?'0':totalList.queryCountTotal?.todayTotal }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="statistical-son-box bg-color4">
                                    <div>
                                        <div class="font-color title1">
                                            <icon-font type="icon-cishu_data-sheet-04" class="font-size4"/>
                                            今日使用次数
                                        </div>
                                        <div class="font-color font-size1">{{ totalList.useCountTotal?.todayTotal == null?'0':totalList.useCountTotal?.todayTotal }}</div>
                                        <div>
                                            <span class="font-color">昨日使用数</span>
                                            <span class="font-color font-size2">{{ totalList.useCountTotal?.yesterdayTotal == null?'0':totalList.useCountTotal?.todayTotal }}</span>
                                        </div>
                                        <div>
                                            <span class="font-color">本月使用数</span>
                                            <span class="font-color font-size3">{{ totalList.useCountTotal?.monthTotal == null?'0':totalList.useCountTotal?.todayTotal }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="statistical-son-box bg-color5">
                                    <div>
                                        <div class="font-color title1">
                                            <icon-font type="icon-cishu_data-sheet-02" class="font-size7"/>
                                            今日费用（元）
                                        </div>
                                        <div class="font-color font-size8">{{ totalList.checkChargeTotal?.todayTotal == null?'0':totalList.checkChargeTotal?.todayTotal }}</div>
                                        <div>
                                            <span class="font-color">昨日费用（元）</span>
                                            <span class="font-color font-size2">{{ totalList.checkChargeTotal?.yesterdayTotal == null?'0':totalList.checkChargeTotal?.yesterdayTotal }}</span>
                                        </div>
                                        <div>
                                            <span class="font-color">本月费用（元）</span>
                                            <span class="font-color font-size3">{{ totalList.checkChargeTotal?.monthTotal == null?'0':totalList.checkChargeTotal?.monthTotal }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    数据列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                    <!-- <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="deleteData" round>删除</el-button> -->
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                   <el-table
                    class="eltable"
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    row-key="id"
                    :max-height="tableHeight"
                    default-expand-all
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                    @selection-change="handleSelectionChange">
                        <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
                        <el-table-column
                        label="昵称">
                        <template #default="scope">{{ scope.row.nickname == null|| scope.row.nickname == ''?'-': scope.row.nickname}}</template>
                        </el-table-column>
                        <el-table-column
                        label="总次数">
                        <template #default="scope">{{ scope.row.checkTotal == null|| scope.row.checkTotal == ''?'-': scope.row.checkTotal}}次</template>
                        </el-table-column>
                        <el-table-column
                        label="拦截次数">
                        <template #default="scope">{{ scope.row.interceptCount == null|| scope.row.interceptCount == ''?'-': scope.row.interceptCount}}次</template>
                        </el-table-column>
                        <el-table-column
                        label="调用第三方次数">
                        <template #default="scope">{{ scope.row.apiCount == null|| scope.row.apiCount == ''?'-': scope.row.apiCount}}次</template>
                        </el-table-column>
                        <el-table-column
                        label="使用次数">
                        <template #default="scope">{{ scope.row.useCount == null|| scope.row.useCount == ''?'-': scope.row.useCount}}次</template>
                        </el-table-column>
                        <el-table-column
                        label="查询次数">
                        <template #default="scope">{{ scope.row.queryCount == null|| scope.row.queryCount == ''?'-': scope.row.queryCount}}次</template>
                        </el-table-column>
                        <el-table-column
                        label="费用">
                        <template #default="scope">￥{{ scope.row.checkCharge == null|| scope.row.checkCharge == ''?'-': scope.row.checkCharge}}</template>
                        </el-table-column>
                        <el-table-column
                        label="日期">
                        <template #default="scope">{{ scope.row.date == null|| scope.row.date == ''?'-': scope.row.date}}</template>
                        </el-table-column>
                        <el-table-column
                        label="创建时间">
                        <template #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import blackCount from '@/api/open/black/blackCount'
import { IconFont } from "@/utils/iconfont"
export default {
    components: {
        IconFont,
    },
    data () {
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:10,
                nickname:'',
                dateBegin:'',
                dateEnd:''
            },
            total:0,
            currentPage: 1,//分页
            multipleSelection: [],//选中集合
            valueTime:'',//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//菜单表
            // loding 状态启用
            loading: true,
            totalList:{}
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.getDataList(true)
    },
    methods: {
        // 查询
        onSubmit() {
            this.searchFrom.dateBegin = this.valueTime[0]
            this.searchFrom.dateEnd = this.valueTime[1]
           this.getDataList(true)
        },
        // 重置
        reset() {
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10,
                nickname:'',
                dateBegin:'',
                dateEnd:''
            },
            this.getDataList(true)
        },
        // 获取数据列表
        getDataList(type) {
            this.loading = type
            blackCount.blackCountList({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.page.records
                this.total = res.data.page.total
                this.totalList = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.searchFrom.page = val
            this.currentPage =val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 批量删除
        deleteData() {
            console.log(this.multipleSelection)
        },
    }
}
</script>

<style lang="scss" scoped>
.menu{
    height: 100%;
    overflow-y: overlay;
    padding-right: 1.125rem;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.out-box{
    padding: .8rem;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}
.pager {
    display: flex;
    justify-content: flex-end;
}
.statistical-box{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem;
}
.statistical-son-box{
    border-radius: .666rem;
    padding: .8rem 0;
    box-shadow: 0px 0px 6px 0px #d3d6e2; 
    // padding: 1rem 3.5rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.title1{
    font-size: 1.4rem;
    display: flex;
    align-items: center;
}
.font-size1{
    text-indent: .5rem;
    color: #637DFF;
    font-weight: 700;
    font-size: 2rem;
}
.font-size2{
    display: inline-block;
    text-align: right;
    width: 5rem;
    font-size: 1rem;
    margin-left: .666rem;
}
.font-size3{
    display: inline-block;
    text-align: right;
    width: 5rem;
    font-size: 1.375rem;
    margin-left: .666rem;
}
.font-size4{
    color: #637DFF;
    margin-right: .5rem;
    font-size: 2rem;
}
.font-size5{
    text-indent: .5rem;
    color: #FF694A;
    font-weight: 700;
    font-size: 2rem;
}
.font-size6{
    color: #FF694A;
    margin-right: .5rem;
    font-size: 2rem;
}
.font-size7{
    color: #FCA603;
    margin-right: .5rem;
    font-size: 2rem;
}
.font-size8{
    text-indent: .5rem;
    color: #FCA603;
    font-weight: 700;
    font-size: 2rem;
}
.img-icon{
    font-size: 12px;
    color: #637DFF;
}
// .bg-color1{
//     background: linear-gradient(90deg, #8de0e9, #37d6e7);;
// }
// .bg-color2{
//     background: linear-gradient(90deg, #e98888, #e53d3d);;
// }
// .bg-color3{
//     background: linear-gradient(90deg, #bc4beb, #814beb);;
// }
// .bg-color4{
//     background: linear-gradient(90deg, #4d4beb, #4b7ceb);;
// }
// .bg-color5{
//     background: linear-gradient(90deg, #97eb4b, #e9eb4b);;
// }
</style>

<style lang="scss">
.menu .el-collapse-item__content {
    padding-bottom: 0 !important;
}
.eltable {
    overflow-y: overlay;
}
</style>